.content-container {
  width: 100%;
  height: calc(100vh - 116px);
  position: fixed;
  left: 0;
  top: 64px;
  padding: 20px 20px 0 20px;
  background: #f0f2f5;
}

.content-container-inner {
  height: 100%;
}

.sider {
  background-color: #fff;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px 0;
}

.ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(1.5);
  margin-left: 3px;
}

.ant-popover-message-title .ant-checkbox-inner,
.ant-popover-message-title .ant-checkbox-input {
  transform: scale(1);
  margin: 0;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-page-header-heading-title {
  line-height: 50px;
}

.page-main>div>div>.ant-tabs>.ant-tabs-nav {
  position: fixed;
  z-index: 9;
  width: calc(100% - 310px);
  background-color: #fff;
}

.page-main>div>div>.ant-tabs>.ant-tabs-content-holder {
  margin-top: 40px;
}

.page-main>div>div>.ant-tabs>.ant-tabs-content-holder .ant-tabs-nav {
  position: fixed;
  height: 610px;
}

.cap-list-sub-tab .ant-tabs-nav {
  width: 210px;
}

.page-main>div>div>.ant-tabs>.ant-tabs-content-holder .ant-tabs-tabpane .ant-tabs-tabpane {
  margin-left: 140px;
  width: calc(100% - 140px);
}

.page-main>div>div>.ant-tabs>.ant-tabs-content-holder .ant-tabs-tabpane .ant-tabs-tabpane.cap-tabpane {
  margin-left: 192px;
  width: calc(100% - 192px);
}

.ant-card-body::before,
.ant-card-body::after {
  display: none;
}

.ant-typography mark {
  padding: 2px 5px;
  background-color: #f48120;
  color: #fff;
}

.dashboard-tab .dashboard-tab {
  position: relative;
  width: 100%;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  width: 100%;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.fa-cuttlefish {
  margin-right: 6px;
}

.daily-log .ant-form-item {
  margin-bottom: 0;
}

.ant-spin-dot-item {
  background-color: white;
}

.ant-spin-text {
  color: #eee;
}

#te-crew-schedule-form .ant-form-item,
#schedule-detail-form-2 .ant-form-item,
#schedule-detail-form-3 .ant-form-item {
  margin-bottom: 10px;
}

.change-status-sync .ant-form-item-label {
  white-space: normal;
  padding: 12px 0;
}

.banner-option .ant-select-selection-item img {
  height: 20px;
  width: auto !important;
}