.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(0, 70, 128);
}

.sign-out-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sign-out-container p {
  color: #fff;
  font-size: 16px;
  margin: 0;
}

.sign-out-btn {
  font-size: 16px;
  height: 40px;
  margin-left: 30px;
  margin-right: 20px;
}

.sign-out-btn.ant-btn {
  border: none;
}

.lang {
  display: inline-block;
  color: #fff;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

.lang-item {
  margin: 0;
}
