/**/
:root {
  --main-color: white;
  --stroke-color: #5d862e;
  --link-color: #29abe2;
}

/**/
.error-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #eee;
}

h1 {
  text-align: center;
  color: var(--stroke-color);
  font-size: 48px;
}

h2 {
  text-align: center;
  color: #666;
  margin-bottom: 80px;
}

.loading h1,
.loading h2 {
  margin-top: 0px;
  opacity: 0;
}

.gears {
  position: relative;
  margin: 0 auto;
  width: auto;
}

.gear {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: var(--stroke-color);
}

.gear:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  content: '';
  border-radius: 50%;
  background: var(--main-color);
}

.gear:after {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 3;
  content: '';
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid var(--stroke-color);
  box-sizing: border-box;
  background: var(--main-color);
}

.gear.one {
  left: -130px;
}

.gear.two {
  top: -75px;
}

.gear.three {
  top: -235px;
  left: 130px;
}

.gear .bar {
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 0;
  width: 150px;
  height: 30px;
  margin-top: -15px;
  border-radius: 5px;
  background: var(--stroke-color);
}

.gear .bar:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  content: '';
  border-radius: 2px;
  background: var(--main-color);
}

.gear .bar:nth-child(2) {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.gear .bar:nth-child(3) {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
}

@keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes anticlockwise {
  0% {
    -webkit-transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes clockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(30deg);
  }

  40% {
    -webkit-transform: rotate(25deg);
  }

  60% {
    -webkit-transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes anticlockwiseErrorStop {
  0% {
    -webkit-transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(-30deg);
  }

  60% {
    -webkit-transform: rotate(-30deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes anticlockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(-30deg);
  }

  40% {
    -webkit-transform: rotate(-25deg);
  }

  60% {
    -webkit-transform: rotate(-30deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

.gear.one {
  animation: anticlockwiseErrorStop 2s linear infinite;
}

.gear.two {
  animation: anticlockwiseError 2s linear infinite;
}

.gear.three {
  animation: clockwiseError 2s linear infinite;
}

.loading .gear.one,
.loading .gear.three {
  animation: clockwise 3s linear infinite;
}

.loading .gear.two {
  animation: anticlockwise 3s linear infinite;
}