.content {
  width: 100%;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-in-btn {
  font-size: 20px;
  height: 50px;
}

.sign-in-btn.ant-btn {
  background: #0067b8;
  color: #fff;
}

.sign-in-btn.ant-btn:hover {
  background: #fff;
  color: #0067b8;
  border-color: #0067b8;
}
